import classNames from "classnames";

import {
  APIFilter,
  getFilterQuery,
  getFilterStateFromFilter,
} from "shared/api/utils";
import {
  areArraysEqual,
  cloneObject,
  randomID,
  splitIgnoringDoubleQuotes,
} from "shared/utils";

import { getFilterGroupStateFromExistsFilterValue } from "features/ui/Filters/FilterTypes/ExistsFilter/utils";
import { decodeOccursFilterAndOptions } from "features/ui/Filters/FilterTypes/OccursFilter/utils";
import {
  ADVANCED_FILTERS_WIDTH_CLASS,
  BASIC_FILTERS_WIDTH_CLASS,
} from "features/ui/Filters/FilterWizard/constants";
import { FilterOperator, FilterState } from "features/ui/Filters/types";
import {
  getAttributesFromFilter,
  getFilterStateCount,
  getOperatorFromAPIOperator,
  isFilterStateCorrect,
} from "features/ui/Filters/utils";
import { SelectOption } from "features/ui/Select";

import {
  ALL_FILTER_SEPARATOR,
  ANY_FILTER_SEPARATOR,
  DEFAULT_FILTER_BUILDER_STATE,
} from "./constants";
import { ANY_ALL_OPTIONS, AnyAll } from "./FilterGroup/AnyAllSelect";
import {
  FilterBlockType,
  FilterGroupState,
  FilterRowState,
  FilterRowStateNotNull,
} from "./types";

export const getNextAnyAll = (parentAnyAll: AnyAll): AnyAll => {
  const currentIndex = ANY_ALL_OPTIONS.findIndex(
    ({ id }) => id === parentAnyAll
  );
  const nextIndex = (currentIndex + 1) % ANY_ALL_OPTIONS.length;

  return ANY_ALL_OPTIONS[nextIndex].id;
};

export const insertInGroupAfterID = (
  groupState: FilterGroupState,
  id: string,
  type: FilterBlockType
): FilterGroupState => {
  const newLayout = cloneObject(groupState);
  const newId = `${type}-${randomID()}`;

  newLayout.children.forEach((rowOrGroup, currentIndex) => {
    const { id: currentChildId, type: currentChildType } = rowOrGroup;

    // check .children of this group for the given id recursively
    if (currentChildType === "group" && currentChildId !== id) {
      newLayout.children[currentIndex] = insertInGroupAfterID(
        rowOrGroup as FilterGroupState,
        id,
        type
      );

      return;
    }

    // we found the row/group to insert after
    if (currentChildId === id) {
      const newIndex = currentIndex + 1;

      if (type === "row") {
        newLayout.children.splice(newIndex, 0, {
          type: "row",
          id: newId,
          attribute: null,
          operator: null,
          values: [],
        });
      } else if (type === "group") {
        const parentAnyAll = newLayout.anyAll;

        newLayout.children.splice(newIndex, 0, {
          type: "group",
          id: newId,
          // If parent group is "any", then the new group default should be "all" and vice versa
          anyAll: getNextAnyAll(parentAnyAll),
          // Always add an empty row as well
          children: [
            {
              type: "row",
              id: `row-${randomID()}`,
              attribute: null,
              operator: null,
              values: [],
            },
          ],
        });
      }

      return;
    }
  });

  return newLayout;
};

export const deleteFromGroupByID = (
  groupState: FilterGroupState,
  id: string
): FilterGroupState => {
  const newLayout = cloneObject(groupState);

  newLayout.children.forEach((rowOrGroup, currentIndex) => {
    const { id: currentChildId, type: currentChildType } = rowOrGroup;

    // check .children of this group for the given id recursively
    if (currentChildType === "group" && currentChildId !== id) {
      newLayout.children[currentIndex] = deleteFromGroupByID(
        rowOrGroup as FilterGroupState,
        id
      );

      return;
    }

    // delete this child but only if it's not the last one in the group
    if (currentChildId === id && newLayout.children.length > 1) {
      newLayout.children.splice(currentIndex, 1);

      return;
    }

    // if it's the last one in the group clear it (set it to an empty
    // filter row as in DEFAULT_FILTER_BUILDER_STATE const) and
    // give it unique id since it can be in a nested group
    if (currentChildId === id && newLayout.children.length === 1) {
      const newId = `row-${randomID()}`;
      newLayout.children = [
        {
          type: "row",
          id: newId,
          attribute: null,
          operator: null,
          values: [],
        },
      ];

      return;
    }

    return;
  });

  return newLayout;
};

export const updateGroupAnyAllByID = (
  groupState: FilterGroupState,
  id: string,
  anyAll: AnyAll
): FilterGroupState => {
  if (groupState.id === id) {
    return { ...groupState, anyAll };
  }

  const updatedChildren = groupState.children.map((child) => {
    if (child.type === "group") {
      return updateGroupAnyAllByID(child as FilterGroupState, id, anyAll);
    }

    return child;
  });

  return { ...groupState, children: updatedChildren };
};

export type EditableRowProperty = "attribute" | "operator" | "values" | "extra";

export const updateRowPropertyByID = (
  groupState: FilterGroupState,
  id: string,
  property: EditableRowProperty,
  value?: string | string[]
): FilterGroupState => {
  const updatedChildren = groupState.children.map((child) => {
    if (child.type === "group") {
      return updateRowPropertyByID(
        child as FilterGroupState,
        id,
        property,
        value
      );
    }

    if (child.id === id) {
      return { ...child, [property]: value };
    }

    return child;
  });

  return { ...groupState, children: updatedChildren };
};

/**
 * Filter string represents a group if it is wrapped with ().
 * - ignores empty space before/after the group.
 */
export const isFilterGroup = (str: string): boolean =>
  /^\(.*\)$/.test(str.trim());

/**
 * Removes outer parenthesis () from the string if they exist in pair.
 * - if they don't exist in pair, it returns the original string.
 * - if there's multiple matching parenthesis on the outside, it removes all of them
 * - make sure to ignore parenthesis inside double quotes
 */
export const removeOuterParenthesis = (str: string): string => {
  if (!isFilterGroup(str)) {
    return str;
  }

  str = str.trim();

  let count = 0;
  let inQuotes = false;

  const countOuterParentheses = (i: number) => {
    if (str[i] === '"') {
      inQuotes = !inQuotes;
    }

    if (!inQuotes) {
      if (str[i] === "(") {
        count++;
      } else if (str[i] === ")") {
        count--;
        if (count === 0 && i !== str.length - 1) {
          return str; // If there are more characters after closing parenthesis, return the original string
        }
      }
    }
  };

  // Check if the parentheses are balanced
  for (let i = 0; i < str.length; i++) {
    const newStr = countOuterParentheses(i);
    if (newStr) return newStr;
  }

  // Remove all outer pairs of balanced parentheses
  while (str.length > 2 && str[0] === "(" && str[str.length - 1] === ")") {
    str = str.slice(1, -1).trim();
    count = 0;
    inQuotes = false;
    for (let i = 0; i < str.length; i++) {
      const newStr = countOuterParentheses(i);
      if (newStr) return newStr;
    }
  }

  return str;
};

/**
 * If it has || (but NOT within "" or within other brackets) then it is an "any" group
 * - ie. 'signal=in:"A"|"B",model=eq:"XYZ",(signal=in:"C"|"D"||mileage=gt:"10000"||(make=eq:"Chevy",model=eq:"XXX"))' is still an "all" group.
 */
export const getGroupFilterAnyAll = (str: string): AnyAll => {
  const origString = str.trim();
  const splitByOr = splitIgnoringDoubleQuotes(
    removeOuterParenthesis(origString),
    ANY_FILTER_SEPARATOR
  );
  // we were able to split by top-level "||"
  if (splitByOr.length > 1) {
    return "any";
  }

  return "all";
};

export const hasMixedTopLevelDelimiters = (str: string) =>
  splitIgnoringDoubleQuotes(removeOuterParenthesis(str), ANY_FILTER_SEPARATOR)
    .length > 1 &&
  splitIgnoringDoubleQuotes(removeOuterParenthesis(str), ALL_FILTER_SEPARATOR)
    .length > 1;

// We do not support "eq" / "neq" as an operator in the UI, so we convert it to "in" / "nin"
const handleOperatorConversion = (operator: FilterOperator): FilterOperator => {
  if (operator === FilterOperator.EQUALS) {
    return FilterOperator.IN;
  }

  if (operator === FilterOperator.NOT_EQUALS) {
    return FilterOperator.NOT_IN;
  }

  return operator;
};

/**
 * Transforms filter query string to FilterGroupState state.
 * This is the reverse of getFiltersQuery.
 */
export const filterBuilderQueryToFilterBuilderState = (
  filterBuilderString: string | undefined
): FilterGroupState | undefined => {
  if (!filterBuilderString) return;

  let rootGroup: FilterGroupState | undefined = {
    ...DEFAULT_FILTER_BUILDER_STATE,
    children: [],
  };

  const parseGroup = (str: string, parentGroup: FilterGroupState): void => {
    const strWithoutOuterParenthesis = removeOuterParenthesis(str);
    const groupAnyAll = getGroupFilterAnyAll(strWithoutOuterParenthesis);
    parentGroup.anyAll = groupAnyAll;

    if (hasMixedTopLevelDelimiters(str)) {
      console.warn(
        `Invalid filter string: mixed delimiters ${strWithoutOuterParenthesis}`
      );
      rootGroup = undefined;

      return;
    }

    // now split by top-level "||" or "," based on groupAnyAll
    const splitBySeparator = splitIgnoringDoubleQuotes(
      strWithoutOuterParenthesis,
      groupAnyAll === "any" ? ANY_FILTER_SEPARATOR : ALL_FILTER_SEPARATOR
    );

    // loop through children and if it's a group, then recursively parse it
    splitBySeparator.forEach((childFilter) => {
      if (isFilterGroup(childFilter)) {
        const currentChildGroupAnyAll = getGroupFilterAnyAll(childFilter);

        // Create a new group for the current level
        const currentGroup: FilterGroupState = {
          ...DEFAULT_FILTER_BUILDER_STATE,
          id: `group-${randomID()}`,
          anyAll: currentChildGroupAnyAll,
          children: [],
        };

        parentGroup.children.push(currentGroup);

        // recursively parse the child group passing the current group as the parent
        parseGroup(childFilter, currentGroup);

        return;
      }

      // if it's not a group, then it's a row - add it as a child of the current group
      // TODO: can we get rid of these two utils somehow? They are only used here.
      const filters = getFilterStateFromFilter(childFilter);
      const attribute = getAttributesFromFilter(filters)[0];

      // Convert "eq" to "in" & "neq" to "nin" because we do not support "eq" as an operator in the UI
      const operator = handleOperatorConversion(filters[attribute].operator);

      const row: FilterRowState = {
        type: "row",
        id: `row-${randomID()}`,
        attribute,
        operator,
        values: filters[attribute].values,
      };

      parentGroup.children.push(row);
    });
  };

  parseGroup(filterBuilderString, rootGroup);

  return rootGroup;
};

/**
 * Filter state is valid when:
 * - it has at least one child
 * - all children have attribute, operator, and values set
 * - no IDs are duplicated
 * - operator is not "not_filtered"
 * - values array is not empty
 * - all the nested groups pass the above conditions too
 */
export const isFilterBuilderStateValid = (
  groupState?: FilterGroupState,
  allowNotFilteredOperator = false
): boolean => {
  if (!groupState || !groupState.children) return false;

  if (!groupState.children.length) return false;

  const ids = new Set<string>();

  return groupState.children.every((child) => {
    // id must be unique
    if (ids.has(child.id)) {
      console.warn("Duplicate id found", child.id);

      return false;
    }

    ids.add(child.id);

    if (child.type === "group") {
      return isFilterBuilderStateValid(
        child as FilterGroupState,
        allowNotFilteredOperator
      );
    }

    const { attribute, operator, values } = child as FilterRowState;

    return (
      !!attribute &&
      !!operator &&
      ((allowNotFilteredOperator && operator === FilterOperator.NOT_FILTERED) ||
        (operator !== FilterOperator.NOT_FILTERED &&
          Array.isArray(values) &&
          values.length > 0))
    );
  });
};

// if it has || or ( or ) but NOT within "" then it is an advanced filter
export const isAdvancedFilter = (filter: string): boolean =>
  /(\|\||\(|\))(?=(?:[^"]|"[^"]*")*$)/.test(filter.trim());

export const isAdvancedFilterState = (filter: FilterGroupState): boolean => {
  // if top-level group is "any" then it's advanced
  // - but only if there are more than one children
  if (filter.anyAll === "any" && filter.children.length > 1) return true;

  // no other groups are expected
  if (filter.children.some((child) => child.type === "group")) return true;

  if (
    filter.children.some(
      (child) =>
        child.type === "row" &&
        hasEmbeddedAdvancedFilters(child as FilterRowState)
    )
  ) {
    return true;
  }

  // every attribute has to appear only once
  const attributes = getFilterGroupStateTopLevelRowAttributes(filter);
  if (new Set(attributes).size !== attributes.length) {
    return true;
  }

  return false;
};

const hasEmbeddedAdvancedFilters = ({ values, operator }: FilterRowState) => {
  if (!values || values.length === 0 || !operator) return false;

  if ([FilterOperator.EXISTS, FilterOperator.NOT_EXISTS].includes(operator)) {
    const filters = getFilterGroupStateFromExistsFilterValue(values[0]);

    return filters && isAdvancedFilterState(filters);
  }

  if ([FilterOperator.OCCURS, FilterOperator.NOT_OCCURS].includes(operator)) {
    const { filters } = decodeOccursFilterAndOptions(values[0]);
    const filterState = filterBuilderQueryToFilterBuilderState(filters);

    return filterState && isAdvancedFilterState(filterState);
  }

  return false;
};

/**
 * Converts a FilterBuilder state to a query string (API filter format).
 * - Returns "" if the state is empty or invalid. Note that default state is also invalid.
 * - The reverse of this is filterBuilderQueryToFilterBuilderState.
 */
export const getFiltersQuery = (
  groupState?: FilterGroupState,
  staticFilters?: APIFilter[]
): string => {
  if (!groupState && !staticFilters) return "";

  if (
    groupState &&
    !isFilterBuilderStateValid(groupState) &&
    !isDefaultAdvancedFilterState(groupState)
  ) {
    return "";
  }

  const transformToQuery = (
    groupState: FilterGroupState<FilterRowStateNotNull>
  ): string => {
    const { anyAll, children } = groupState;

    return children
      .map((child) => {
        if (child.type === "group") {
          return `(${transformToQuery(child as typeof groupState)})`;
        }

        const { values } = child as FilterRowStateNotNull;

        // skip attributes that have empty values array
        if (!values.length) return null;

        return getFilterQuery(child);
      })
      .filter(Boolean)
      .join(anyAll === "any" ? ANY_FILTER_SEPARATOR : ALL_FILTER_SEPARATOR);
  };

  let filterQuery =
    (groupState &&
      transformToQuery(
        groupState as FilterGroupState<FilterRowStateNotNull>
      )) ||
    "";

  // If we have staticFilters we need to make sure this is included in the query via top-level commas (ALL)
  let staticFiltersQuery = "";
  if (staticFilters && staticFilters.length > 0) {
    staticFiltersQuery = staticFilters
      .reduce((acc, { name, op, value }) => {
        const filterQuery = getFilterQuery({
          id: name,
          type: "row",
          attribute: name,
          operator: getOperatorFromAPIOperator(op),
          values: value as string[],
        });
        if (filterQuery) {
          acc.push(filterQuery);
        }

        return acc;
      }, [] as string[])
      .join(ALL_FILTER_SEPARATOR);
  }

  // In the case of top-level group having "any" as a separator, we have to add another group so that static filters
  // will be ANDed with the rest of the filters (otherwise we'd introduce mixed delimiters, which is not a valid FilterGroupState!)
  if (staticFiltersQuery) {
    if (groupState?.anyAll === "any" && groupState?.children?.length > 1) {
      filterQuery = `(${filterQuery})`;
    }

    filterQuery += (filterQuery && ALL_FILTER_SEPARATOR) + staticFiltersQuery;
  }

  return filterQuery;
};

export const filterStateToFilterGroupState = (
  filterState?: FilterState | FilterGroupState
): FilterGroupState => {
  if (!filterState) return DEFAULT_FILTER_BUILDER_STATE;

  // if it's already a FilterGroupState return it as is
  if (
    isFilterBuilderStateValid(filterState as FilterGroupState) ||
    isDefaultAdvancedFilterState(filterState as FilterGroupState)
  ) {
    return filterState as FilterGroupState;
  }

  const copy = cloneObject(filterState);

  const filterGroupState: FilterGroupState = cloneObject(
    DEFAULT_FILTER_BUILDER_STATE
  );

  // convert FilterState -> FilterGroupState
  Object.keys(copy as FilterState).forEach((fieldName, index) => {
    const newGroupChild: FilterRowState = {
      type: "row",
      id: `row-${index}`,
      attribute: fieldName,
      ...(copy as FilterState)[fieldName],
    };

    // override the first one which is part of the default state
    if (index === 0) {
      filterGroupState.children[0] = newGroupChild;
    } else {
      filterGroupState.children.push(newGroupChild);
    }
  });

  return filterGroupState;
};

/**
 * Runs a function on all row children of a given FilterGroupState and returns the FilterGroupState.
 * - If onlyTopLevel is true, it only runs the function on the top-level children.
 */
export const runOnAllRowChildren = (
  filterState: FilterGroupState,
  rowFormatFunction: (row: FilterRowStateNotNull) => void,
  onlyTopLevel = false
): FilterGroupState => {
  const runOnAllFilterRowStates = (
    groupState: FilterGroupState
  ): FilterGroupState => {
    groupState.children.forEach((child) => {
      if (child.type === "group") {
        !onlyTopLevel && runOnAllFilterRowStates(child as FilterGroupState);
      } else {
        rowFormatFunction(child as FilterRowStateNotNull);
      }
    });

    return groupState;
  };

  return runOnAllFilterRowStates(filterState);
};

export const runOnAllFilterRowStatesWithAttribute = (
  attribute: string,
  filterState: FilterGroupState,
  rowFormatFunction: (row: FilterRowStateNotNull) => void,
  onlyTopLevel = false
): FilterGroupState => {
  const runOnAllFilterRowStates = (
    groupState: FilterGroupState
  ): FilterGroupState => {
    groupState.children.forEach((child) => {
      if (child.type === "group" && !onlyTopLevel) {
        runOnAllFilterRowStates(child as FilterGroupState);
      } else {
        const row = child as FilterRowStateNotNull;
        if (row.attribute === attribute) {
          rowFormatFunction(row);
        }
      }
    });

    return groupState;
  };

  return runOnAllFilterRowStates(filterState);
};

export const getFilterGroupStateTopLevelRowAttributes = (
  groupState?: FilterGroupState
): string[] =>
  groupState?.children
    ? groupState.children
        .filter(({ type }) => type === "row")
        .filter((child) => (child as FilterRowState).attribute)
        .map((child) => (child as FilterRowStateNotNull).attribute)
    : [];

/**
 * Returns the top-level row with the given attribute.
 */
export const getTopLevelRowFromFilterGroupState = (
  attribute: string,
  filters?: FilterGroupState
): FilterRowStateNotNull | undefined => {
  if (!filters) return;

  const foundChildren = filters.children.filter(
    (child) => child.type === "row" && child.attribute === attribute
  ) as FilterRowState[];

  if (!foundChildren.length) return;

  return foundChildren[0] as FilterRowStateNotNull;
};

export const removeAttributesFromFilterGroupState = (
  filters: FilterGroupState,
  attributesToRemove: string[]
): FilterGroupState => {
  const copy = cloneObject(filters);

  attributesToRemove.forEach((attribute) => {
    // remove all type="row" objects with attribute = key
    const removeKey = (group: FilterGroupState) => {
      group.children = group.children.filter((child) => {
        if (child.type === "row") {
          return child.attribute !== attribute;
        } else {
          removeKey(child);

          return true;
        }
      });
    };
    removeKey(copy);
  });

  if (copy.children.length === 0) return DEFAULT_FILTER_BUILDER_STATE;

  return copy;
};

export const removeAttributesWithPrefixFromFilterGroupState = (
  filters: FilterGroupState,
  prefix: string
): FilterGroupState => {
  const copy = cloneObject(filters);

  // remove all type="row" objects with attribute that starts with prefix
  const removeKey = (group: FilterGroupState) => {
    group.children = group.children.filter((child) => {
      if (child.type === "row") {
        return !child.attribute?.startsWith(prefix);
      } else {
        removeKey(child);

        return true;
      }
    });
  };

  removeKey(copy);

  if (copy.children.length === 0) return DEFAULT_FILTER_BUILDER_STATE;

  return copy;
};

export const isDefaultAdvancedFilterState = (
  filterState?: FilterGroupState
): boolean =>
  DEFAULT_FILTER_BUILDER_STATE.id === filterState?.id &&
  DEFAULT_FILTER_BUILDER_STATE.anyAll === filterState?.anyAll &&
  DEFAULT_FILTER_BUILDER_STATE.type === filterState?.type &&
  filterState?.children?.length === 1 &&
  filterState.children[0].type === "row" &&
  (filterState.children[0].values
    ? filterState.children[0].values.length === 0
    : false);

/**
 * Updates or adds a row to the filter group state based on the attribute.
 * Only works for top-level children.
 */
export const updateOrAddRowFilterGroupState = (
  filters: FilterGroupState | undefined,
  rowToAddOrUpdate?: FilterRowStateNotNull
): FilterGroupState => {
  const copy = cloneObject(filters || DEFAULT_FILTER_BUILDER_STATE);

  if (!rowToAddOrUpdate) return copy;

  const currentAttribute = rowToAddOrUpdate.attribute;

  // if current pending state is default, then update existing row-0
  if (isDefaultAdvancedFilterState(copy)) {
    copy.children[0] = rowToAddOrUpdate;

    return copy;
  }

  // if not default, then check if the attribute already exists
  const foundChildRow = copy.children?.find(
    (child) => child.type === "row" && child.attribute === currentAttribute
  );

  // if it does, update it
  if (foundChildRow) {
    copy.children = copy.children?.map((child) => {
      if (child.type === "row" && child.attribute === currentAttribute) {
        return rowToAddOrUpdate;
      }

      return child;
    });
  } else {
    // if it doesn't, add it
    copy.children?.push(rowToAddOrUpdate);
  }

  return copy;
};

export const getValuesForAttributeFromFilterState = (
  attribute: string,
  filterState: FilterGroupState
): string[] => {
  // Only search for attribute in the top-level group's children
  const foundChild = filterState.children.find(
    (child) => child.type === "row" && child.attribute === attribute
  );

  return foundChild ? (foundChild as FilterRowState).values || [] : [];
};

export const areFiltersEqual = (
  filters1?: FilterGroupState,
  filters2?: FilterGroupState
) => {
  if (!filters1 || !filters2) return false;

  if (getFilterStateCount(filters1) !== getFilterStateCount(filters2)) {
    return false;
  }

  for (const key of getFilterGroupStateTopLevelRowAttributes(filters1)) {
    const rowFromFilters2 = getTopLevelRowFromFilterGroupState(key, filters2);
    const rowFromFilters1 = getTopLevelRowFromFilterGroupState(key, filters1);

    if (!rowFromFilters2) {
      return false;
    }

    if (rowFromFilters1?.operator !== rowFromFilters2?.operator) {
      return false;
    }

    if (
      rowFromFilters1?.values?.slice().sort().join("") !==
      rowFromFilters2?.values?.slice().sort().join("")
    ) {
      return false;
    }
  }

  return true;
};

// almost the same as singleFiltersMatch for FilterState
export const filterRowsMatch = (
  filter1?: FilterRowState,
  filter2?: FilterRowState
): boolean => {
  if (filter1 === filter2) return true;

  if (!filter1 || !filter2) return false;

  return (
    Object.keys(filter1).length === Object.keys(filter2).length &&
    Object.keys(filter1).every((key) => Object.keys(filter2).includes(key)) &&
    filter1.operator === filter2.operator &&
    areArraysEqual(filter1.values || [], filter2.values || []) &&
    // naive
    JSON.stringify(filter1.extra) === JSON.stringify(filter2.extra)
  );
};

export const getSelectOptionsFromFilterGroupState = (
  filters: FilterGroupState | undefined
): { [key: string]: SelectOption[] } => {
  if (!filters) return {};

  return getFilterGroupStateTopLevelRowAttributes(filters).reduce(
    (acc: { [key: string]: SelectOption[] }, attribute) => {
      const values = getTopLevelRowFromFilterGroupState(
        attribute,
        filters
      )?.values;
      if (!values) return acc;

      acc[attribute] = Array.isArray(values)
        ? values.filter((v) => v).map((v) => ({ id: v, value: v }))
        : [{ id: values, value: values }];

      return acc;
    },
    {}
  );
};

/**
 * Fake "merge" arbitrary number of FilterGroupState objects by concatenating their children.
 * Ignores merging by ID or managing duplicates etc ..
 * - It ignores invalid filter states
 * - makes sure IDs are unique
 * - if any of the filters has "any" as anyAll, it leave it in a separate group within top-level "all" group
 */
export const mergeFilterGroupStates = (
  ...states: (FilterGroupState | undefined)[]
): FilterGroupState => {
  // Filter out undefined states & states with invalid children (ie. DEFAULT_FILTER_BUILDER_STATE)
  const validStates = states
    .filter((state): state is FilterGroupState => state !== undefined)
    .filter((state) => state && isFilterBuilderStateValid(state));

  if (validStates.length === 0) {
    return DEFAULT_FILTER_BUILDER_STATE;
  }

  // Start with a top-level "all" group
  const mergedState: FilterGroupState = {
    id: `group-${randomID()}`,
    type: "group",
    anyAll: "all",
    children: [],
  };

  // Iterate over the valid states and merge them
  for (const state of validStates) {
    if (state.anyAll === "any") {
      // If the state has "any" as anyAll, create a separate group
      mergedState.children.push({
        ...state,
        id: `group-${randomID()}`,
        children: state.children.map((child) => ({
          ...child,
          id: `${child.type}-${randomID()}`,
        })),
      });
    } else {
      // Otherwise, concatenate the children directly
      mergedState.children = mergedState.children.concat(
        state.children.map((child) => ({
          ...child,
          id: `${child.type}-${randomID()}`,
        }))
      );
    }
  }

  // If there's only one child and it's a group, return that group instead
  if (
    mergedState.children.length === 1 &&
    mergedState.children[0].type === "group"
  ) {
    return mergedState.children[0] as FilterGroupState;
  }

  return mergedState;
};

export const getFilterMenuWrapperClass = (isAdvancedFilter: boolean) =>
  classNames({
    [BASIC_FILTERS_WIDTH_CLASS]: !isAdvancedFilter,
    [ADVANCED_FILTERS_WIDTH_CLASS]: isAdvancedFilter,
  });

export const areFilterStatesEqual = (
  objA: FilterGroupState,
  objB: FilterGroupState
): boolean => {
  if (objA === objB) return true;

  if (!objA || !objB) return false;

  if (objA.anyAll !== objB.anyAll) return false;

  if (objA.children.length !== objB.children.length) return false;

  return objA.children.every((child, index) => {
    if (child.type === "group") {
      return areFilterStatesEqual(
        child as FilterGroupState,
        objB.children[index] as FilterGroupState
      );
    }

    const rowA = child as FilterRowState;
    const rowB = objB.children[index] as FilterRowState;

    // .slice() used so we dont mutate original values order!
    const sortedValuesA = rowA?.values?.slice().sort();
    const sortedValuesB = rowB?.values?.slice().sort();

    return (
      rowA.attribute === rowB.attribute &&
      rowA.operator === rowB.operator &&
      rowA.values?.length === rowB.values?.length &&
      sortedValuesB &&
      sortedValuesA?.every((val, i) => val === sortedValuesB[i])
    );
  });
};

/**
 * Transform the old FilterState to new FilterGroupState
 * Old: { fieldName: { values: [value1, value2], operator: "in" }}
 * New: see DEFAULT_FILTER_BUILDER_STATE
 */
export const oldFilterStateToNew = (
  filterState?: FilterState | FilterGroupState
): FilterGroupState | undefined => {
  if (!filterState) return undefined;

  if ("type" in filterState && filterState.type === "group") {
    if (isFilterBuilderStateValid(filterState as FilterGroupState)) {
      return filterState as FilterGroupState;
    }
  }

  if (isFilterStateCorrect(filterState)) {
    return filterStateToFilterGroupState(filterState);
  }

  return filterState;
};
